import React from "react";
import Hero from "../components/landingpage/Hero";
import BenefitSection from "../components/landingpage/benefits_section";

const LandingPage = () => {
	return (
		<div className="App">
			<Hero />
			<BenefitSection />
		</div>
	);
};

export default LandingPage;
