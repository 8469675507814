import axiosInstance from './configEndpoints';


/**
 * Asynchronous function that makes a GET request to the server using axiosInstance
 * and returns the response data.
 *
 * @param {...string} args - The arguments to be passed to axiosInstance.get.
 *                          The first argument is the URL to make the request to.
 * @returns {Promise} - A promise that resolves to the response data.
 */
const fetcher = async (...args) => {
    try {
        if (!args.length) {
            throw new Error('No arguments provided');
        }
        // Make the GET request using axiosInstance
        const response = await axiosInstance.get(...args);
        
        // Return the response data
        return response.data;
    } catch (error) {
        // Handle any errors here
        console.error(`Error fetching data for args ${args}: ${error}`);
        throw error;
    }
}

export default fetcher
