import React from 'react';
// import '../styles/sass/Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <p>Copyright &copy; {new Date().getFullYear()} Sprout Collab</p>
    </div> 
    )
}


export default Footer;