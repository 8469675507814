import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const ChecklistCloner = ({ onChecklistsChange }) => {
  const [checklists, setChecklists] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [newChecklist, setNewChecklist] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddChecklist = (event) => {
    event.preventDefault();
    if (!newChecklist) {
      setErrorMessage("Please enter a checklist");
      return;
    }

    if (checklists.includes(newChecklist)) {
      setErrorMessage("Checklist already exists");
      return;
    }

    setChecklists([...checklists, newChecklist]);
    setDescriptions([...descriptions, ""]);
    setNewChecklist("");
    setErrorMessage("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "checklist") {
      setNewChecklist(value);
    } else {
      const index = checklists.indexOf(newChecklist);
      const updatedDescriptions = [...descriptions];
      updatedDescriptions[index] = value;
      setDescriptions(updatedDescriptions);
    }
  };

  const handleDeleteChecklist = (checklistToDelete) => {
    const updatedChecklists = checklists.filter((checklist) => checklist !== checklistToDelete);
    const updatedDescriptions = descriptions.filter((_, index) => updatedChecklists[index] !== checklistToDelete);
    setChecklists(updatedChecklists);
    setDescriptions(updatedDescriptions);
  };

  useEffect(() => {
    onChecklistsChange({ checklists, descriptions });
  }, [checklists, descriptions]);

  return (
    <div className="clone-items-container">
      <p>Add Task checklists</p>
      <div className="new-item">
        <div>
          <input
            type="text"
            value={newChecklist}
            placeholder="Add checklist"
            name="checklist"
            onChange={handleInputChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            value={descriptions[checklists.indexOf(newChecklist)]}
            onChange={handleInputChange}
          />
        </div>
        <button className="btn--outline" onClick={handleAddChecklist}>
          Add
        </button>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <ul>
        {checklists.map((checklist, index) => (
          <li key={index}>
            <span>{checklist}</span>
            <button className="delete-btn" onClick={() => handleDeleteChecklist(checklist)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChecklistCloner;
