import React, { useState } from "react";
import { Link } from "react-router-dom";
import GrowthBenefits from "../../components/landingpage/growth_benefits";

const LoginPage = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		setEmail("");
		setPassword("");
	};

	const handleGoogleLogin = () => {
		console.log("Google login clicked");
		// Implement Google login logic here
	};

	const handleFacebookLogin = () => {
		console.log("Facebook login clicked");
		// Implement Facebook login logic here
	};

	return (
		<div className="login-page">
			{/* Login form on the left */}
			<form
				className="login-form"
				onSubmit={handleSubmit}
			>
				<h2>Login</h2>
				<label htmlFor="email">Email:</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<label htmlFor="password">Password:</label>
				<input
					type="password"
					id="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
				<Link to="#">Forgot Password?</Link>
				<div className="form--Buttons">
					<button
						className="btn--secondary"
						type="submit"
					>
						Login
					</button>
				</div>
				<div className="social-login">
					<p>or login with</p>
					<button
						type="button"
						className="btn--secondary"
						onClick={handleGoogleLogin}
					>
						Google
					</button>
					<button
						type="button"
						className="btn--secondary"
						onClick={handleFacebookLogin}
					>
						Facebook
					</button>
				</div>

				<p>
					Don't have an account? <Link to="/register">Register</Link>
				</p>
			</form>

			<GrowthBenefits />
		</div>
	);
};

export default LoginPage;
