import { configureStore } from "@reduxjs/toolkit";
import checklistSliceReducer from "./ChecklistSlice";
import collabReducer from './collabSlice';
import goalsReducer from "./goalsSlice";
import goalTypesReducer from "./goalTypeSlice";
import loadingSlice from './mainLoaderSlice';
import myGoalSlice from "./myGoalSlice";
import myProjectSliceReducer from "./myProjectSlice";
import taskSliceReducer from "./TaskSlice";
import projectsSliceReducer from './ProjectsSlice';
import resourceSliceReducer from './ResourceSlice'

const store = configureStore({
	reducer: {
		goals: goalsReducer,
		mygoals: myGoalSlice,
		goalTypes: goalTypesReducer,
		myprojects: myProjectSliceReducer,
		projects: projectsSliceReducer,
		tasks: taskSliceReducer,
		taskCheckList: checklistSliceReducer,
		collaborations: collabReducer,
		loading: loadingSlice,
		resources: resourceSliceReducer
		
	},
});

export default store;
