import React from "react";
import "react-circular-progressbar/dist/styles.css";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import LoginPage from "./pages/auth/Login";
import RegisterPage from "./pages/auth/Register";
import GoalDetails from "./components/goals/goalDetailPage";
import Goals from "./components/goals/Goals";
import HomeLoggedIn from "./pages/HomeLoggedIn";
import LandingPage from "./pages/LandingPage";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";

import GoalCatergories from "./components/goals/GoalCatergory";
import ProjectPage from "./pages/ProjectPage";
import SeTT from "./pages/SettingsPage";
import TestPage from "./pages/Testpage";
import TestPage1 from "./pages/Testpage1";
import Counter1 from "./redux/counter";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="/"
			element={<Layout />}
		>
			<Route
				index
				element={<LandingPage />}
			/>
			<Route
				path="login"
				element={<LoginPage />}
			/>
			<Route
				path="register"
				element={<RegisterPage />}
			/>
			<Route
				path="home"
				element={<HomeLoggedIn />}
			/>
			<Route
				path="sett"
				element={<SeTT />}
			/>

			<Route
				path="goal-details/:goalId"
				element={<GoalDetails />}
			/>
			<Route
				path="goals/:typeId"
				element={<Goals />}
			/>
			<Route
				path="/projects/:projectId/tasks"
				element={<ProjectPage />}
			/>

			<Route
				path="/counter"
				element={<Counter1 />}
			/>
			<Route
				path="/goal-catergories"
				element={<GoalCatergories />}
			/>

			<Route
				path="/analytics"
				element={<TestPage />}
			/>
			<Route
				path="/comment"
				element={<TestPage1 />}
			/>

			<Route
				path="*"
				element={<NoPage />}
			/>
		</Route>
	)
);
const App = () => {
	return (
		<div className="App">
			<RouterProvider router={router} />
			<ToastContainer />
		</div>
	);
};

export default App;
